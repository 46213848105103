<template>
  <div class="page">
    <div class="page-ct">
      <div class="detail-line">
        <div class="title">{{ land.zdzl || land.dkwz }}</div>
        <div class="des">({{ land.sheng }}>{{ land.shi }}>{{ land.xzq }})</div>
        <div class="state">{{ land.stateText }}</div>
        <div class="follow" :class="{ active: land.star }" @click="follow">
          <div class="icon"></div>
          关注
        </div>
        <div class="btn assess" @click="handleAddProject">看项目</div>
        <Icon type="ios-redo" @click="back" />
      </div>
      <div class="detail-info-box">
        <div class="info-title">地块详情</div>
        <div class="line">
          <div class="item">
            <div class="title">地块位置：</div>
            <div class="des">{{ land.zdzl || land.dkwz }}</div>
          </div>
          <div class="item">
            <div class="title">公告时间：</div>
            <div class="des">
              {{ land.fbsjCj == "null" ? "" : land.fbsjCj }}
            </div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">土地四至：</div>
            <div class="des">{{ land.tdszfw }}</div>
          </div>
          <div class="item">
            <div class="title">起始时间：</div>
            <div class="des">{{ land.gpkssj }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">地块编号：</div>
            <div class="des">{{ land.zdbhCr || land.zdbhCj }}</div>
          </div>
          <div class="item">
            <div class="title">截止时间：</div>
            <div class="des">{{ land.gpjzsj }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">用地性质：</div>
            <div class="des">{{ land.tdytCr }}</div>
          </div>
          <div class="item">
            <div class="title">成交时间：</div>
            <div class="des">{{ land.zpgjzsj }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">出让方式：</div>
            <div class="des">{{ land.crfsCr }}</div>
          </div>
          <div class="item">
            <div class="title">交易状况：</div>
            <div class="des">{{ land.stateText }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">总用地面积：</div>
            <div class="des">{{ land.zydmj ? `${land.zydmj}平方米` : "" }}</div>
          </div>
          <div class="item">
            <div class="title">起始价：</div>
            <div class="des">{{ land.qsjms }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">代征面积：</div>
            <div class="des">{{ land.dzmj }}</div>
          </div>
          <div class="item">
            <div class="title">成交价：</div>
            <div class="des">{{ land.cjjms ? `${land.cjjms}万元` : "" }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">建设用地面积：</div>
            <div class="des">
              {{ land.jsydmj ? `${land.jsydmj}平方米` : "" }}
            </div>
          </div>
          <div class="item">
            <div class="title">溢价率：</div>
            <div class="des">{{ land.yjl != "" ? land.yjl : "" }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">规划建筑面积：</div>
            <div class="des">
              {{ land.ghjzmj ? `${land.ghjzmj}平方米` : "" }}
            </div>
          </div>
          <div class="item">
            <div class="title">受让单位：</div>
            <div class="des">{{ land.sldw }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">容积率：</div>
            <div class="des">{{ land.rjlms }}</div>
          </div>
          <div class="item">
            <div class="title">推出土地单价：</div>
            <div class="des">
              {{ land.tctddj ? `${land.tctddj}元/平方米` : "" }}
            </div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">开发强度：</div>
            <div class="des">{{ land.kfqd }}</div>
          </div>
          <div class="item">
            <div class="title">成交土地单价：</div>
            <div class="des">
              {{ land.cjtddj ? `${land.cjtddj}元/平方米` : "" }}
            </div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">建筑密度：</div>
            <div class="des">
              {{ land.jzmdms == "null" ? "" : land.jzmdms }}
            </div>
          </div>
          <div class="item">
            <div class="title">推出每亩价：</div>
            <div class="des">
              {{ land.tcmmjg ? `${land.tcmmjg}万元/亩` : "" }}
            </div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">绿化率：</div>
            <div class="des">{{ land.lhlms }}</div>
          </div>
          <div class="item">
            <div class="title">成交每亩价：</div>
            <div class="des">
              {{ land.cjmmj ? `${land.cjmmj}万元/亩` : "" }}
            </div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">商业比例：</div>
            <div class="des">{{ land.sybl }}</div>
          </div>
          <div class="item">
            <div class="title">推出楼面价：</div>
            <div class="des">
              {{ land.tclmjg ? `${land.tclmjg}元/平方米` : "" }}
            </div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">限制高度：</div>
            <div class="des">
              {{ land.jzxgms == "null" ? "" : land.jzxgms }}
            </div>
          </div>
          <div class="item">
            <div class="title">成交楼面价：</div>
            <div class="des">
              {{ land.cjlmj ? `${land.cjlmj}元/平方米` : "" }}
            </div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">出让年限：</div>
            <div class="des">{{ land.crnx }}</div>
          </div>
          <div class="item">
            <div class="title">保证金：</div>
            <div class="des">{{ land.bzjms }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">保障房类型：</div>
            <div class="des"></div>
          </div>
          <div class="item">
            <div class="title">加价幅度：</div>
            <div class="des">{{ land.jjfd }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">成交保障房面积：</div>
            <div class="des">{{ land.cjbzfmj }}</div>
          </div>
          <div class="item">
            <div class="title">咨询电话：</div>
            <div class="des"></div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">推出保障房面积：</div>
            <div class="des">{{ land.tcbzfmj }}</div>
          </div>
          <div class="item">
            <div class="title">公告编号：</div>
            <div class="des">{{ land.btCj }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">配建保障房情况：</div>
            <div class="des">{{ land.tczzfmj }}</div>
          </div>
          <div class="item">
            <div class="title">出让单位：</div>
            <div class="des">{{ land.crjg }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">推出自住房情况：</div>
            <div class="des">{{ land.tczzfmj }}</div>
          </div>
          <div class="item">
            <div class="title">土地公告：</div>
            <div class="des">
              <template v-if="land.state == 2">
                <!-- <a :href="land.urlCj" target="_blank">查看公告</a> -->
                <a :href="land.urlCr" target="_blank">查看公告</a>
              </template>
              <template v-else>
                <a
                  v-show="land.urlCr != ''"
                  style="marginright: 10px"
                  :href="land.urlCr"
                  target="_blank"
                  >查看出让公告</a
                >&nbsp;|&nbsp;
                <a v-show="land.urlCj != ''" :href="land.urlCj" target="_blank"
                  >查看成交公告</a
                >
              </template>
            </div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">成交自住房面积：</div>
            <div class="des">{{ land.tcznjzmj }}</div>
          </div>
          <div class="item">
            <div class="title">备注：</div>
            <div class="des">{{ land.bz == "null" ? "" : land.bz }}</div>
          </div>
        </div>
        <div class="line">
          <div class="item">
            <div class="title">配建自住房面积：</div>
            <div class="des">{{ land.tcbzfmj }}</div>
          </div>
          <div class="item">
            <!-- <div class="title">备注：</div>
                        <div class="des">{{land.bzCr=='null'?'--':land.bzCr}}</div> -->
          </div>
        </div>
      </div>

      <div class="other-box">
        <div class="title">相关附件</div>
        <div class="table">
          <div class="head">
            <div class="tr">时间</div>
            <div class="tr">名称</div>
            <div class="tr">操作</div>
          </div>
          <div class="body"></div>
        </div>
      </div>
    </div>

    <addProjectModal
      :address="address"
      :id="addProjectId"
      type="place"
    ></addProjectModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import addProjectModal from "@/components/modal/addProject";
import { placeDetail, getByIdAndType, followPlace } from "@/api/placeMarket";
import { addPlaceProject } from "@/api/projectAnalysis";

export default {
  name: "placeOptions",
  components: {
    addProjectModal,
    // BottomBar,
    // HorizenTable
  },
  data() {
    return {
      id: 0,
      addProjectId: -1,
      land: {},
      // 框选位置时传入的经纬度
      address: {},
    };
  },
  computed: {
    ...mapState(["landUse", "sellWay", "landProvinceList"]),
    showAddProjectModal: {
      get() {
        return this.$store.state.modal.showAddProjectModal;
      },
      set(value) {
        this.$store.state.modal.showAddProjectModal = value;
      },
    },
  },
  mounted() {
    let id = this.$route.query.id;
    let type = this.$route.query.type;
    let methods = this.$route.query.methods;
    this.id = id;
    this.addProjectId = Number(id);
    this.type = type;
    this.methods = methods;
    this.land = [];
    this.getDetail();
  },
  methods: {
    follow() {
      followPlace({
        state: this.land.star ? -1 : 1,
        type: 1,
        unionId: this.id,
      }).then((data) => {
        if (this.land.star) {
          // this.$msg.success("取消关注成功")
        this.$msg.error({ type: "remind", code: 4035 });

          this.land.star = false;
        } else {
          // this.$msg.success("关注成功")

        this.$msg.error({ type: "remind", code: 4036 });

          this.land.star = true;
        }
      });
    },
    getDetail() {
      if (this.type == "option") {
        placeDetail({
          id: this.id,
        }).then((data) => {
          data.tctddj && (data.tctddj = (+data.tctddj).toFixed(2));
          data.cjtddj && (data.cjtddj = (+data.cjtddj).toFixed(2));
          data.tcmmjg && (data.tcmmjg = (+data.tcmmjg).toFixed(2));
          data.cjmmj && (data.cjmmj = (+data.cjmmj).toFixed(2));
          data.tclmjg && (data.tclmjg = (+data.tclmjg).toFixed(2));
          data.cjlmj && (data.cjlmj = (+data.cjlmj).toFixed(2));
          this.land = data;
        });
      } else {
        getByIdAndType({
          id: this.id,
          type: this.methods,
        }).then((data) => {
          this.land = data;
        });
      }
    },
    back() {
      this.$router.push('/Insight/PlaceSearch');
    },

    handleAddProject() {
      this.addProjectId = this.land.id;
      this.address.lng = this.land.jd;
      this.address.lat = this.land.wd;
      this.showAddProjectModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/style/common.scss";

.page {
  width: 100%;
  min-height: calc(100vh - 80px);
  margin: 0 auto;
  padding-top: 20px;
  background: #fff;
  overflow: hidden;
  color: #000;

  .page-ct {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 1200px;
    padding: 0 20px;
  }
  .detail-line {
    @include flex(flex-start);
    width: 100%;
    color: #fff;
    .title {
      color: #000;
      font-size: 18px;
    }
    .des {
      color: #444;
      font-size: 16px;
    }
    .state {
      @include flex;
      width: 50px;
      height: 24px;
      margin-left: 10px;
      background: rgba(218, 115, 113, 1);
      border-radius: 5px;
    }
    .follow {
      @include flex;
      margin: 0 10px;
      color: #e3393c;
      font-size: 12px;
      cursor: pointer;
      .icon {
        @include bgSrc("no-star.png");
        width: 14px;
        height: 14px;
        margin-right: 2px;
      }
      &.active {
        .icon {
          @include bgSrc("star.png");
        }
      }
    }
    .btn {
      @include flex;
      width: 58px;
      height: 28px;
      background: rgba(0, 182, 255, 1);
      border-radius: 5px;
      cursor: pointer;
    }
    .ivu-icon-ios-redo {
      margin-left: auto;
      color: #00b6ff;
      font-size: 36px;
      transform: rotateY(180deg);
      cursor: pointer;
    }
    .location {
      width: 68px;
      margin-left: 10px;
    }
  }
  .detail-info-box {
    width: 100%;
    padding-bottom: 28px;
    border: 1px solid #e3e3e5;
    .info-title {
      @include flex(flex-start);
      width: 100%;
      height: 50px;
      margin-bottom: 30px;
      padding-left: 38px;
      color: #444;
      font-size: 18px;
      border-left: 4px solid rgba(#00b6ff, 0.5);
      border-bottom: 1px solid #e3e3e5;
      background-color: rgba(#00b6ff, 0.02);
    }
    .line {
      @include flex;
      width: 100%;
      padding-left: 30px;
      margin-bottom: 10px;
      font-size: 14px;
      .item {
        @include flex(flex-start);
        flex: 1;
        .title {
          flex-shrink: 0;
          margin-right: 25px;
          width: 115px;
          color: #999;
          text-align: right;
        }
        .des {
          color: #333;
        }
      }
    }
  }

  .other-box {
    margin-right: auto;
    font-size: 14px;
    .title {
      margin: 15px 0;
      color: #444;
    }
    .table {
      border: 1px solid #eaeaea;
      .head {
        @include flex;
        width: 760px;
        height: 40px;
        background-color: #fafafd;
        .tr {
          @include flex;
          width: 120px;
          &:nth-child(2) {
            flex-grow: 1;
            justify-content: flex-start;
            padding-left: 15px;
          }
        }
      }
      .body {
        .line {
          @include flex;
          height: 40px;
          border-bottom: 1px solid #eaeaea;
          &:last-child {
            border: none;
          }
        }
        .tr {
          @include flex;
          width: 120px;
          height: 100%;
          border-right: 1px solid #eaeaea;
          &:nth-child(2) {
            flex-grow: 1;
            justify-content: flex-start;
            padding-left: 15px;
          }
          &:last-child {
            border: none;
          }
        }
      }
    }
  }
}
</style>
